export function filterUniqueObjects(objectsArray) {
    const uniqueObjects = objectsArray.reduce((acc, object) => {
    const key = object['CODGRUPOCPGO']; // Replace 'propertyName' with your actual property name
    if (!acc[key]) {
    acc[key] = object; // Add unique object to accumulator
    }
    return acc;
    }, {});    
  
    return Object.values(uniqueObjects); // Convert object to array of unique objects
    }