import React, { useState, useEffect } from "react"
import axios from "axios"
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { isIOS, isAndroid } from "react-device-detect"
import Buttons from "./botonera/Buttons"
import { filterUniqueObjects } from "./utils"

export default function PaymentsChannelsInfo() {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(
    `${process.env.GATSBY_API_URL}/metodosDePago.png`
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const [selectedChannelCode, setSelectedChannelCode] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("dbo/treasury/get_payment_channel")
        const data = response.data.result

        const objectsWithComponentTypeProp = data?.map((item) => {
          return {
            ...item,
            COMPONENT_TYPE:
              item.DESCCANALPAGO === item.DESCGRUPOCPGO ? "button" : "dropdown",
          }
        })

        const objectsComponentTypeGroup = objectsWithComponentTypeProp?.map(
          (item) => {
            if (item.COMPONENT_TYPE === "dropdown") {
              return {
                CODGRUPOCPGO: item.CODGRUPOCPGO || "",
                COMPONENT_TYPE: item.COMPONENT_TYPE || "",
                DESCGRUPOCPGO: item.DESCGRUPOCPGO || "",
                ORDENGRUPOCPGO: item.ORDENGRUPOCPGO || "",
              }
            }
            return item
          }
        )

        const objectsbyComponentTypeWithChildren = filterUniqueObjects(
          objectsComponentTypeGroup
        )
          ?.map((item) => {
            if (item.COMPONENT_TYPE === "dropdown") {
              return {
                ...item,
                children: objectsWithComponentTypeProp
                  ?.filter((obj) => obj.CODGRUPOCPGO === item.CODGRUPOCPGO)
                  .sort((a, b) => a.ORDENCANALPAGO - b.ORDENCANALPAGO),
              }
            }
            return item
          })
          .sort((a, b) => a.ORDENGRUPOCPGO - b.ORDENGRUPOCPGO)

        setGroups(objectsbyComponentTypeWithChildren)
      } catch (error) {
        console.error("Error al consumir la API:", error)
      }
    }

    fetchData()

    if (isAndroid || isIOS) {
      setModalOpen(true)
    }
  }, [])

  const handleImagenSeleccionada = (item) => {
    setSelectedChannelCode(item?.CODGRUPOCPGO + item?.CODCANALPAGO)
    setImagenSeleccionada(item.URL)

    if (isIOS || isAndroid) {
      setModalOpen(true)
    }
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <div className="contenedor">
      <h1 className="titulo">Conoce nuestros canales de pago</h1>
      <div className="contenido">
        <div className="botonera">
          <Buttons
            groups={groups}
            onImagenSeleccionada={handleImagenSeleccionada}
            selectedCode={selectedChannelCode}
          />
        </div>
        <div className="panel-info">
          {imagenSeleccionada && !isIOS && !isAndroid ? (
            <img
              src={imagenSeleccionada}
              alt="Imagen seleccionada"
              className="imagen-seleccionada"
            />
          ) : null}
        </div>
      </div>

      <Dialog onClose={handleClose} open={modalOpen}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {imagenSeleccionada && (
            <img
              src={imagenSeleccionada}
              alt="Imagen seleccionada"
              style={{ maxWidth: "100%", height: "auto", aspectRatio: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>

      <style jsx>{`
        .contenedor {
          font-family: Arial, sans-serif;
          width: 100%;
          margin: 1% auto;
          padding: 20px;
        }
        .titulo {
          text-align: center;
          color: white;
          background: linear-gradient(
            60deg,
            rgb(127, 210, 203),
            rgb(10, 177, 165)
          );
          padding: 10px;
          margin-top: 0;
          font-size: 2rem;
        }
        .contenido {
          display: flex;
          gap: 20px;
          margin-top: 20px;
        }
        .botonera {
          flex: 0.33;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .panel-info {
          flex: 0.66;
          background-color: white;
          border-radius: 8px;
        }
        .imagen-seleccionada {
          width: 100%;
          height: auto;
          aspect-ratio: auto;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }
        @media (max-width: 1000px) {
          .panel-info {
            display: none;
          }
          .botonera {
            flex: 1;
          }
          .titulo {
            font-size: 2.2rem;
          }
        }
      `}</style>
    </div>
  )
}
